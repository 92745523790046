export const requiredKeys = [
	'apparentTemperatureHigh',
	'apparentTemperatureLow',
	'icon',
	'summary',
	'time'
];

export const dayTemplate = requiredKeys
	.reduce((template, key) => {
		if (key === 'time') template[key] = Date.now();
		else if (key === 'icon') template[key] = 'clear-day';
		else if (key === 'summary') template[key] = 'Weather not yet loaded';
		else template[key] = 0;

		return template;
	}, {});

export const isValidDay = day => requiredKeys
	.reduce((isValid, key) => isValid && typeof day[key] === 'undefined', true);
