import 'core-js/stable';
import 'regenerator-runtime/runtime';
import App from '~/views/App';
import { round } from '~/utilities/number';
import { startServiceWorker } from '~/modules/service-worker';
import store from '~/modules/store/index';
import { titleCase } from '@gabegabegabe/utils/dist/string';
import Vue from 'vue';

const CURRENCY_DIGITS = 2;
// eslint-disable-next-line no-undefined
Vue.filter('locale-string', num => Number(num).toLocaleString(undefined, {
	minimumFractionDigits: CURRENCY_DIGITS,
	maximumFractionDigits: CURRENCY_DIGITS
}));
Vue.filter('round', round);
Vue.filter('title-case', titleCase);
Vue.filter('uppercase', str => str.toUpperCase());

const app = new Vue({ ...App, name: 'Homepage', store }).$mount('#app');

startServiceWorker();

export default app;
