const rounding = {
	usd: { total: 0, individual: 2 },
	crypto: 3
};

export const gteZero = val => Number(val) >= 0;

export const round = (val, type) => {
	if (type === 'fiat') val = Number(val).toFixed(rounding.usd.individual);
	else if (type === 'crypto') val = Number(val).toFixed(rounding.crypto);
	else if (type === 'int') val = Number(val).toFixed(0);

	return val;
};
