import cryptoTickerModule from '~/modules/store/modules/crypto-ticker';
import rssFeedModule from '~/modules/store/modules/rss-feed';
import searchFormModule from '~/modules/store/modules/search-form';
import taskManagerModule from '~/modules/store/modules/tasks';
import Vue from 'vue';
import weatherForecastModule from '~/modules/store/modules/weather-forecast';
import Vuex, { Store } from 'vuex';

Vue.use(Vuex);

const store = new Store({
	modules: {
		cryptoTicker: cryptoTickerModule,
		rssFeed: rssFeedModule,
		searchForm: searchFormModule,
		taskManager: taskManagerModule,
		weatherForecast: weatherForecastModule
	}
});

export default store;
