import log from '~/modules/log';

const RSS_FEEDS_KEY = 'rss-feeds';
const RSS_POSTS_KEY = 'rss-posts';

const cacheFeeds = feeds => {
	window.localStorage.setItem(RSS_FEEDS_KEY, JSON.stringify(feeds));
};
const cachePosts = posts => {
	window.localStorage.setItem(RSS_POSTS_KEY, JSON.stringify(posts));
};

const rssFeedModule = {
	namespaced: true,
	state: {
		feeds: [],
		posts: []
	},
	mutations: {
		addFeed (state, feed) {
			state.feeds.push(feed);
			cacheFeeds(state.feeds);
		},
		removeFeed (state, index) {
			state.feeds.splice(index, 1);
			cacheFeeds(state.feeds);
		},
		setFeeds (state, feeds) {
			state.feeds = feeds;
			cacheFeeds(state.feeds);
		},
		setPosts (state, posts) {
			state.posts = posts;
			cachePosts(state.posts);
		}
	}
};

try {
	const cachedFeeds = window.localStorage.getItem(RSS_FEEDS_KEY);
	const content = JSON.parse(cachedFeeds);
	if (content && Array.isArray(content)) rssFeedModule.state.feeds = content;
} catch (err) {
	log.error(err, 'Failed to load news feeds from cache.');
}

try {
	const cachedPosts = window.localStorage.getItem(RSS_POSTS_KEY);
	const content = JSON.parse(cachedPosts);
	if (content && Array.isArray(content)) rssFeedModule.state.posts = content;
} catch (err) {
	log.error(err, 'Failed to load news posts from cache.');
}

export default rssFeedModule;
