import notify from '@gabeotisbenson/notify';
import { ocean } from 'base16';

notify.configure({
	colors: {
		error: ocean.base08,
		log: ocean.base0B,
		warn: ocean.base0A
	}
});

export default notify;
