import log from '~/modules/log';

const COINS_KEY = 'coins';
const COIN_OPTIONS_KEY = 'coinOptions';

const setCachedCoins = coins => {
	window.localStorage.setItem(COINS_KEY, JSON.stringify(coins));
};

const cryptoTickerModule = {
	namespaced: true,
	state: {
		coins: [],
		coinOptions: []
	},
	mutations: {
		addNewCoin (state, coin) {
			state.coins.push({ ...coin });
			setCachedCoins(state.coins);
		},
		removeCoin (state, coin) {
			const index = state.coins.indexOf(coin);
			if (index !== -1) state.coins.splice(index, 1);
			setCachedCoins(state.coins);
		},
		saveCoins (state, coins) {
			state.coins = coins;
			setCachedCoins(state.coins);
		},
		setOptions (state, options) {
			state.coinOptions = options.map(o => ({
				id: o.id,
				name: o.name,
				symbol: o.symbol,
				value: o.priceUsd
			}));
			const optionsString = JSON.stringify(state.coinOptions);
			window.localStorage.setItem(COIN_OPTIONS_KEY, optionsString);
		},
		setValue (state, id, value) {
			const index = state.coins.map(c => c.id).indexOf(id);
			if (index === -1) return;
			state.coins[index].value = Number(value);
			setCachedCoins(state.coins);
		},
		setValuesWithRates (state, rates) {
			rates.forEach(rate => {
				const index = state.coins.map(c => c.id).indexOf(rate.id);
				if (index === -1) return;

				state.coins[index].value = Number(rate.rateUsd);
			});
			setCachedCoins(state.coins);
		},
		setValuesWithUpdates (state, updates) {
			let coins = {};
			if (typeof updates === 'object') coins = { ...updates };
			else try {
				coins = JSON.parse(updates);
			} catch (err) {
				log.error(err);
				coins = {};
			}
			for (const id in coins) {
				const index = state.coins.map(c => c.id).indexOf(id.toLowerCase());
				if (index === -1) return;
				const { [id]: value } = coins;
				state.coins[index].value = value;
			}
			setCachedCoins(state.coins);
		}
	}
};

try {
	const cachedCoins = window.localStorage.getItem(COINS_KEY);
	const content = JSON.parse(cachedCoins);
	if (content) cryptoTickerModule.state.coins = content;
} catch (err) {
	log.error(err, 'Failed to load portfolio coins from cache.');
}

try {
	const cachedCoinOptions = window.localStorage.getItem(COIN_OPTIONS_KEY);
	const content = JSON.parse(cachedCoinOptions);
	if (content) cryptoTickerModule.state.coinOptions = content;
} catch (err) {
	log.error(err, 'Failed to load cryptocurrency options from cache.');
}

export default cryptoTickerModule;
