import log from '~/modules/log';
import {
	isValidDay,
	requiredKeys
} from '~/utilities/day';

// Grab API key from cache
const APIKEY_KEY = 'api-key';
const FORECAST_KEY = 'weather-forecast';
const NUM_DAYS_IN_WEEK = 7;

const weatherForecastModule = {
	namespaced: true,
	state: {
		apiKey: null,
		forecast: []
	},
	mutations: {
		setApiKey (state, apiKey) {
			state.apiKey = apiKey;
			window.localStorage.setItem(APIKEY_KEY, JSON.stringify(state.apiKey));
		},
		setForecast (state, forecast) {
			state.forecast = forecast
				.map(day => requiredKeys
					.reduce((newDay, key) => {
						const { [key]: value } = day;
						newDay[key] = value;

						return newDay;
					}, {}));
			window.localStorage.setItem(FORECAST_KEY, JSON.stringify(state.forecast));
		}
	}
};

try {
	const cachedKey = window.localStorage.getItem(APIKEY_KEY);
	const content = JSON.parse(cachedKey);
	if (content) weatherForecastModule.state.apiKey = content;
} catch (err) {
	log.error(err, 'Failed to load weather api key from cache.');
}

try {
	const cachedForecast = window.localStorage.getItem(FORECAST_KEY);
	const content = (JSON.parse(cachedForecast) || []).filter(isValidDay);
	const isValidForecast = content && content.length === NUM_DAYS_IN_WEEK;
	if (isValidForecast) weatherForecastModule.state.forecast = content;
} catch (err) {
	log.error(err, 'Failed to load weather forecast from cache.');
}

export default weatherForecastModule;
