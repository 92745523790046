import consola from 'consola';
import notify from '~/modules/notify';

export default {
	error (err, msg = '') {
		if (msg) {
			consola.error(msg, err);
			notify.error(msg);
		} else {
			consola.error(err);
		}
	},
	warn (msg = '') {
		if (!msg) return;
		consola.warn(msg);
		notify.warn(msg);
	},
	note (msg = '') {
		if (!msg) return;
		consola.log(msg);
		notify.log(msg);
	}
};
