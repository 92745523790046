/**
 * A collection of Array sorters.  These functions are intended to be passed
 * into Array.prototype.sort.
 * @module array/sorters
 */

type SortOrder = 'asc' | 'desc';

// eslint-disable-next-line no-shadow
enum SortResult {
	LT = -1,
	EQ = 0,
	GT = 1
}

/**
 * Used to sort an array of objects by a specified key and in a specified order
 * @function
 * @name byKey
 * @param {string} key - The key by which to sort the object array
 * @param {string} [order=asc] - The order in which to sort the array
 * @returns {function} - A function that compares two objects by the specified
 * key and returns which direction the first item should be moved in the array.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const byKey = (key: string, order: SortOrder = 'asc') => (a: Readonly<Record<string, any>>, b: Readonly<Record<string, any>>): SortResult => {
	if (a[key] > b[key]) return order === 'asc' ? 1 : -1;
	if (a[key] < b[key]) return order === 'asc' ? -1 : 1;

	return 0;
};

/**
 * Used to sort an array in random order.
 * @function
 * @name shuffle
 * @returns {function} - A function that randomly returns 1 or -1
 */
// eslint-disable-next-line @typescript-eslint/no-magic-numbers,no-confusing-arrow
export const shuffle = (): SortResult => Math.random() > 0.5 ? 1 : -1;
