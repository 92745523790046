import { singleItem } from '@gabegabegabe/utils/dist/array/reducers';

export const searchProviders = [
	{
		name: 'DuckDuckGo',
		url: 'https://duckduckgo.com',
		queryParameter: 'q',
		isDefault: true
	},
	{
		name: 'Searx',
		url: 'https://searx.ninja',
		queryParameter: 'q'
	},
	{
		name: 'Qwant',
		url: 'https:/www.qwant.com',
		queryParameter: 'q'
	},
	{
		name: 'MetaGer',
		url: 'https://metager.org/meta/meta.ger3',
		queryParameter: 'eingabe'
	},
	{
		name: 'Mojeek',
		url: 'https://www.mojeek.com/search',
		queryParameter: 'q'
	}
];

export const defaultSearchProvider = searchProviders
	.filter(({ isDefault }) => isDefault)
	.reduce(singleItem);
