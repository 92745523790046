import { defaultSearchProvider } from '~/modules/search-providers';
import log from '~/modules/log';

const PROVIDER_KEY = 'search-provider';

const searchFormModule = {
	namespaced: true,
	state: {
		searchProvider: defaultSearchProvider
	},
	mutations: {
		setSearchProvider (state, provider) {
			state.searchProvider = provider;
			window.localStorage.setItem(PROVIDER_KEY, JSON.stringify(provider));
		}
	}
};

try {
	const cachedSearchProvider = window.localStorage.getItem(PROVIDER_KEY);
	const content = JSON.parse(cachedSearchProvider);
	if (content) searchFormModule.state.searchProvider = content;
} catch (err) {
	log.error(err);
}

export default searchFormModule;
