import log from '~/modules/log';

const LOAD_EVENT = 'load';
const SERVICE_WORKER_KEY = 'serviceWorker';
const SERVICE_WORKERS_SUPPORTED = SERVICE_WORKER_KEY in navigator;

const registerServiceWorker = async () => {
	try {
		await navigator.serviceWorker.register('~/service-worker.js', { scope: '/' });
	} catch (err) {
		log.error(err);
	}
};

export const startServiceWorker = () => {
	if (!SERVICE_WORKERS_SUPPORTED) return;

	window.addEventListener(LOAD_EVENT, registerServiceWorker);
};
