import log from '~/modules/log';
import { toKey } from '@gabegabegabe/utils/dist/array/mappers';

const TASKS_KEY = 'tasks';

const setCachedTasks = tasks => {
	window.localStorage.setItem(TASKS_KEY, JSON.stringify(tasks));
};

const taskManagerModule = {
	namespaced: true,
	state: { tasks: [] },
	getters: {
		nextId: state => {
			let i = 0;
			const ids = state.tasks.map(toKey('id'));
			while (ids.indexOf(i) !== -1) ++i;

			return i;
		}
	},
	mutations: {
		addTask (state, task) {
			state.tasks.push({ ...task });
			setCachedTasks(state.tasks);
		},
		removeTask (state, taskId) {
			const index = state.tasks.map(t => t.id).indexOf(taskId);
			if (index !== -1) state.tasks.splice(index, 1);
			setCachedTasks(state.tasks);
		},
		setTasks (state, tasks) {
			state.tasks = tasks;
			setCachedTasks(state.tasks);
		},
		updateTask (state, { index, task }) {
			if (index < 0) return;
			for (const key in task) {
				const { [key]: value } = task;
				state.tasks[index][key] = value;
			}
			setCachedTasks(state.tasks);
		}
	}
};

try {
	const cachedTasks = window.localStorage.getItem(TASKS_KEY);
	const content = JSON.parse(cachedTasks);
	if (content) taskManagerModule.state.tasks = content;
} catch (err) {
	log.error(err, 'Failed to load tasks from cache.');
}

export default taskManagerModule;
