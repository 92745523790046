<template lang="pug">
	main.flex.column
		SearchForm
		TaskManager
		WeatherForecast(@display-settings='displaySettings($event)')
		div.grid.two-columns
			CryptoTicker(@display-settings='displaySettings($event)')
			RssFeed(@display-settings='displaySettings($event)')
		div.settings-modal-wrapper(v-if='settingsComponent')
			SettingsModal(
				:settings-component='settingsComponent',
				@hide-settings='hideSettings'
			)
</template>

<script>
	const CryptoTicker = async () => await import('~/views/crypto-ticker/CryptoTicker');
	const RssFeed = async () => await import('~/views/rss-feed/RssFeed');
	const SearchForm = async () => await import('~/views/search-form/SearchForm');
	const SettingsModal = async () => await import('~/views/SettingsModal');
	const TaskManager = async () => await import('~/views/task-manager/TaskManager');
	const WeatherForecast = async () => await import('~/views/weather-forecast/WeatherForecast');

	export default {
		name: 'App',
		components: {
			CryptoTicker,
			RssFeed,
			SearchForm,
			SettingsModal,
			TaskManager,
			WeatherForecast
		},
		data () {
			return {
				settingsComponent: false
			};
		},
		methods: {
			displaySettings (component) {
				if (component) this.settingsComponent = component;
			},
			hideSettings () { this.settingsComponent = false; }
		}
	};
</script>

<style lang="scss" scoped>
	main {
		max-width: 96rem;
		margin: auto;
	}
</style>
